<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 619.38 619.26"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon points="494.31 322.41 455.56 281.66 337.3 400.79 337.3 0 282.07 0 282.07 402.76 162.94 282.75 125.1 322.65 309.58 507.13 494.31 322.41"/><rect y="564.71" width="619.38" height="54.55"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconDownload',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
